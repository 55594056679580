import { Link, usePage } from '@inertiajs/react';
import { Box, Button, Container, Group, rem, Stack, Title } from '@mantine/core';

export default function Unlock(props) {
  const user = usePage().props?.auth?.user;

  return (
    <Box bg="gfdark.9" id="trust">
      <Container py={100} {...props}>
        <Stack align="center">
          <Title align={'center'} lh={1.2} fw={500} fz={rem(48)} lts={rem(-3)} c={'white'}>
            Unlock Exclusive Benefits Today!
          </Title>
          {/*<Text c="gfdark.5" fz={rem(18)} fw={'lighter'} align={'center'}>*/}
          {/*  Create an account now and enjoy your first art investment.*/}
          {/*</Text>*/}

          <Group mt={50}>
            <Button component={Link} href={user ? '/art-sales' : '/register'} size="lg" variant="outline-gf" c="white">
              BUY NOW
            </Button>
          </Group>
        </Stack>
      </Container>
    </Box>
  );
}
